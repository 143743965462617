.content h2 {
  margin-top: 4rem;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: -1px;
}

.content h3 {
  margin-top: 4rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.content h4 {
  margin-top: 4rem;
  font-size: 1.25rem;
  font-weight: 700;
}

.content pre {
  overflow: auto;
  border-radius: 1rem;
  background-color: var(--card);
}
